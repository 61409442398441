<template>
<!--  捐物公示 -->
  <div class="benevolence">
      <!-- 这是捐物公示内容 -->
	  <Newslist :list="benevolencelist" :nodename="'xxgk_jwgs'" @childByValue="childByValue"></Newslist>
    </div>
</template>

<script>
import Newslist from '@/components/newslist.vue'
export default {
  name: 'Benevolence',
  props:{
	  benevolencelist:{
	    type: Array,
	    default: () =>[]
	  }
  },
  components: {
	Newslist
  },
  methods: {
	childByValue (childByValue,nodename) {
	  this.$emit('childByValue', childByValue,nodename)
	}   
  }
}
</script>

<style lang="less" scoped>
.benevolence{
	width: 908px;
	padding: 39px 40px 41px 40px;
}
</style>
