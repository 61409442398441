import { render, staticRenderFns } from "./benevolence.vue?vue&type=template&id=09f0b38e&scoped=true&"
import script from "./benevolence.vue?vue&type=script&lang=js&"
export * from "./benevolence.vue?vue&type=script&lang=js&"
import style0 from "./benevolence.vue?vue&type=style&index=0&id=09f0b38e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09f0b38e",
  null
  
)

export default component.exports